/**
 * FRAG Plausible Analytics integration.
 */

document.addEventListener('turbolinks:load', (event) => {
  // Bail if we're on an admin page.
  if (/admin\./.test(window.location.hostname)) {
    return;
  }

  // Bail if we're an admin user.
  const userDataEl = document.querySelector(
    '[data-frag-selector="frag-user-data"]'
  );
  if (userDataEl) {
    const userData = JSON.parse(userDataEl.textContent);
    if (userData.role === 'admin') {
      return;
    }
  }

  if (typeof plausible !== 'undefined') {
    // Fetch our real pathname.
    const { url } = event.data;
    let path = url.split(window.location.origin).join('');

    // Adjust /server/:id URLs to remove the ID portion.
    const pathMatch = path.match(/\/servers(\/?\d+).*/);
    if (pathMatch) {
      path = path.split(pathMatch[1]).join('');

      // Add /server/:id/show path for the root path.
      if (path.match(/\/servers\/?$/)) {
        path = '/servers/show';
      }
    }

    plausible('pageview', { u: `${window.location.origin}${path}` });
  }
});
