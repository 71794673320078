/**
 * Base app functionality.
 */

/**
 * Application styling.
 */
/**
 * Injects Tailwind's base styles.
 */
/* purgecss start ignore */
import 'tailwindcss/base'; // eslint-disable-line
/* purgecss end ignore */

/**
 * Inject Tailwind component classes.
 */
import 'tailwindcss/components'; // eslint-disable-line

/**
 * Import our own base styles.
 */
import '../css/application.scss';

/**
 * Import our own components.
 */
import '../css/components/alerts.scss';
import '../css/components/buttons.scss';
import '../css/components/dropdown.scss';
import '../css/components/form-switch.scss';
import '../css/components/forms.scss';
import '../css/components/hamburger.scss';
import '../css/components/icons.scss';
import '../css/components/links.scss';
import '../css/components/modals.scss';
import '../css/components/navigation.scss';
import '../css/components/propertylist.scss';
import '../css/components/sidebar.scss';
// import '../css/components/slider.scss';
import '../css/components/spinner.scss';
import '../css/components/table.scss';
import '../css/components/tooltips.scss';
import '../css/components/typography.scss';

// This has to go last so that we have all of our component classes included.
import '../css/components/body-marketing.scss';

/**
 * Import our own features.
 */
import '../css/features/layout.scss';
import '../css/features/menu.scss';
import '../css/features/v-modal.scss';

/**
 * Import Tailwind's utility classes.
 */
import 'tailwindcss/utilities'; // eslint-disable-line

/**
 * Import our custom utilities.
 */
import '../css/utilities/background-patterns.scss';
import '../css/utilities/positioning.scss';
import '../css/utilities/shadow.scss';
import '../css/utilities/transitions.scss';

// Load Rails UJS.
import Rails from '@rails/ujs';

// Load Bootstrap alerts.
import 'bootstrap/js/dist/alert';

// Load StimulusJS menu_controller.
// import '@stimulus/polyfills';
import { Application } from 'stimulus';
import MenuController from '../controllers/menu_controller';

if (!window._rails_loaded) {
  Rails.start();
}

// Load Turbolinks.
const Turbolinks = require('turbolinks');

Turbolinks.start();

const application = Application.start();
application.register('menu', MenuController);

// FRAG analytics.
require('../src/frag_analytics');
