/**
 * Menu controller. Controls the menu.
 */

import { Controller } from 'stimulus';

export default class extends Controller {
  _handleTurbolinksLoad = null;

  initialize() {
  }

  connect() {
    this.documentElement = document.querySelector('html');
    this.contentElement = document.querySelector('.main-content');
    this.sidebarElement = document.querySelector('.sidebar');

    // Add an event listener for Turbolinks page changes, so we can dismiss the
    // sidebar.
    if (!this._handleTurbolinksLoad) {
      this._handleTurbolinksLoad = this.handleTurbolinksLoad.bind(this);

      document.addEventListener('turbolinks:load', this._handleTurbolinksLoad);
    }
  }

  disconnect() {
  }

  toggle(event) {
    // Prevent default link behaviour
    event.preventDefault();
    event.stopPropagation();

    // Toggle the sidebar when a click is detected.
    this.toggleSidebar();
  }

  handleTurbolinksLoad() {
    // Dismiss the sidebar if it's currently active.
    if (this.sidebarIsOpen()) {
      this.closeSidebar();
    }
  }

  sidebarIsOpen() {
    return this.documentElement.classList.contains('sidebar-is-open');
  }

  openSidebar() {
    // The crux of the idea is to add some attribute/class that triggers the
    // visual change, you don't necessarily only have to add one class.
    this.documentElement.classList.add('sidebar-is-open');
  }

  closeSidebar() {
    this.documentElement.classList.remove('sidebar-is-open');
  }

  handleClickBeyondSidebar(event) {
    // Ignore if the sidebar is not open.
    if (!this.sidebarIsOpen()) {
      return;
    }

    // This implementation assumes that all clicks beyond the sidebar close it.
    // Your sidebar might open modals and other elements not within the sidebar,
    // in which case you'd have to make this more precise!
    if (!this.sidebarElement.contains(event.target)) {
      this.closeSidebar();
    }
  }

  toggleSidebar() {
    return this.sidebarIsOpen() ? this.closeSidebar() : this.openSidebar();
  }
}
